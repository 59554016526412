import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import tw from 'tailwind.macro';
import {
  FaArrowsAlt,
  FaBalanceScale,
  FaBed,
  FaBriefcaseMedical,
  FaChartPie,
  FaCity,
  FaCog,
  FaDove,
  FaGraduationCap,
  FaHandHoldingUsd,
  FaLandmark,
  FaLeaf,
  FaPeopleCarry,
  FaRegChartBar,
  FaTabletAlt,
  FaTractor,
} from 'react-icons/fa';
import Button from '../components/button';
import Grid from '../components/grid';
import Layout from '../components/layout';
import LeadText from '../components/leadText';
import Masthead from '../components/masthead';
import Section from '../components/section';
import SEO from '../components/seo';
import PageNavigator from '../components/pageNavigator';
import Panel from '../components/panel';
import SafeLink from '../components/safeLink';

const Summit2021 = ({ data, location }) => {
  const parentTitle =
    'The Centre for Social Justice presents a virtual event:';
  const pageTitle =
    '3rd Annual Social Justice Summit and International Conference';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:30vh;`]}
      />
      <PageNavigator
        items={{
          welcome: 'Welcome',
          programme: 'Programme',
          speakers: 'Speakers',
          /* registration: 'Registration', */
          watch: 'Watch now',
          /* press: 'Press coverage', */
        }}
        buttonText="Watch now"
        buttonLink="#watch"
      />
      <Section id="welcome">
        <h2>Welcome!</h2>
        <Img
          fluid={data.summit2021Banner.childImageSharp.fluid}
          alt="3rd Annual Social Justice Summit and International Conference, 11-12 October 2021"
          css={tw`w-full`}
        />
        <hr css={tw`mt-3 mb-10`} />

        <h3>
          Social justice, economic equality and peace - A new socio-economic
          pathway for South Africa and the continent
        </h3>
        <LeadText>
          The purpose of the 3<sup>rd</sup> Annual Social Justice Summit is to
          reflect on the state of social justice in South Africa, focusing on
          economic equality, the impact of Covid-19 regulatory responses, the
          adequacy of current policy frameworks for rebuilding better and
          restitution, as well as the impact of economic inequality on peace and
          the rule of law. The summit will also reflect on the factors behind
          the recent social unrest, future scenarios and ways through which the
          Musa Plan for Social Justice (Social Justice M-Plan) could be
          leveraged for rebuilding better together, focusing on the economy and
          social fabric in a manner that leverages inclusion for sustainable
          growth, development and peace. It will also review progress on the
          implementation of the <em>Social Justice Declaration</em> adopted at
          the Inaugural Summit and the <em>Social Justice M-Plan</em> and emerge
          with an agenda for the next phase to accelerate the progress on the
          achievement of the United Nations Sustainable Development Goals
          (SDGs), focusing on advancing economic equality and ending poverty by
          2030.
        </LeadText>
        <Button to="/downloads/summit-2021/summit-2021-summary-report.pdf" size="md" css={tw`mb-2`}>Download Summit summary report &rarr;</Button>&nbsp;
        <Button to="/downloads/summit-2021/summit-2021-full-report.pdf" size="md" css={tw`mb-2`}>Download Summit full report &rarr;</Button>&nbsp;
        <Button to="/downloads/summit-2021/conference-2021-summary-report.pdf" size="md" css={tw`mb-2`}>Download Conference summary report &rarr;</Button>&nbsp;        
        <Button to="/downloads/summit-2021/conference-2021-full-report.pdf" size="md" css={tw`mb-2`}>Download Conference full report &rarr;</Button>
        <hr css={tw`my-5`} />
        <h3>Watch now</h3>
        <LeadText>
          Watch recordings of summit and conference sessions below. Looking for
          the 2020 summit?{' '}
          <SafeLink to="/summit-2020/" css={tw`underline`}>
            Click here.
          </SafeLink>
        </LeadText>
        <Button to="#watch">Watch now</Button>
      </Section>
      <Section id="programme" isShaded>
        <h2>Programme</h2>
        <h3 css={tw`mt-20`}>
          11 October 2021:
          <br />
          The International Conference on Law, Policy and Social Justice: Taking
          Economic Equality Seriously
        </h3>
        <LeadText>
          The conference is a global platform that seeks to bring together
          social justice researchers and other stakeholders to reflect on
          advancing equality and ending poverty in economies globally, with a
          special focus on progress, shortfalls and prospects regarding law and
          policy responses to the Covid-19 pandemic and Sustainable Development
          Goals (SDG) on the African continent.
        </LeadText>
        <LeadText>
          The conference will further explore the role of economic equality in
          sustaining peace, stability and the rule of law in emerging
          democracies.
        </LeadText>
        <LeadText>
          We welcome the submission of abstracts on social justice research for
          papers to be presented at the international conference. Presentations
          should focus on leveraging law reform and SDGs to accelerate economic
          parity and to achieve Social Justice outcomes.
        </LeadText>
        <Button
          to="/downloads/events/2021-09-conference-programme.pdf"
          size="md"
          css={tw`mr-2`}
        >
          Download programme &rarr;
        </Button>
        <Button
          to="https://abstracts.conftools.co.za/137/signup"
          size="md"
          css={tw`mb-8`}
        >
          Submit an abstract &rarr;
        </Button>
        <LeadText>The themes for the conference are:</LeadText>
        <Grid gridCols={2} mdGridCols={2} lgGridCols={2}>
          <Panel size="sm" css={tw`flex items-center`} className="col-span-2">
            <FaHandHoldingUsd
              css={[
                tw`mr-4 text-maroon text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>
              Land reform, property rights, and the distribution of assets
            </h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaCity
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Business ownership</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaArrowsAlt
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Financial inclusion</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaChartPie
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Wealth and income distribution</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaBed
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Social security and the basic income grant</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaTractor
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Agriculture and food security</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaTabletAlt
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Education and digital inclusion</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`} className="col-span-2">
            <FaRegChartBar
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>
              Social justice resonant economic theories including indices for
              measuring economic growth
            </h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`} className="col-span-2">
            <FaCog
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>
              Emerging tools for social impact conscious economic planning
            </h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`} className="col-span-2">
            <FaLandmark
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>
              The role of law reform and judicial activism in advancing economic
              parity and SDG goals
            </h3>
          </Panel>
        </Grid>
        <h3 css={tw`mt-20`}>
          12 October 2021:
          <br />
          The 3rd Annual Social Justice Summit: Social justice, economic
          equality and peace - A new socio-economic pathway for South Africa and
          the continent
        </h3>
        <LeadText>
          The objective of the summit is to review progress on economic
          equality, factors behind identified challenges and emerge with a
          proposed civil society plan of action around adjusted pathways for the
          achievement of an equal economy in all municipalities, aligned with
          the Social Justice M-Plan key objectives and the SDGs.{' '}
        </LeadText>
        <Button
          to="/downloads/events/2021-09-summit-programme.pdf"
          size="md"
          css={tw`mb-8`}
        >
          Download the programme &rarr;
        </Button>
        <LeadText>The themes for the Social Justice Summit are:</LeadText>
        <Grid gridCols={2} mdGridCols={2} lgGridCols={2}>
          <Panel size="sm" css={tw`flex items-center`} className="col-span-2">
            <FaHandHoldingUsd
              css={[
                tw`mr-4 text-maroon text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>
              Land reform, property rights, and the distribution of assets
            </h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaCity
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Business ownership</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaArrowsAlt
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Financial inclusion</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaChartPie
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Wealth and income distribution</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaBed
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Social security and the basic income grant</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaTractor
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Agriculture and food security</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaTabletAlt
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Education and digital inclusion</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`} className="col-span-2">
            <FaRegChartBar
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>
              Social justice resonant economic theories including indices for
              measuring economic growth
            </h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`} className="col-span-2">
            <FaCog
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>
              Emerging tools for social impact conscious economic planning
            </h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`} className="col-span-2">
            <FaLandmark
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>
              The role of Parliament in advancing economic parity and SDG goals
            </h3>
          </Panel>
        </Grid>
      </Section>
      <Section id="speakers">
        <h2>Keynote Speakers</h2>
        <LeadText>
          We have an exciting array of speakers, including the ones below
          &mdash; and many more.
        </LeadText>
        {/* <Button to="#" size="md" css={tw`mb-10`}>
          View the full list of speakers, with bios &rarr;
            </Button> */}
        <h3>International Conference on Social Justice</h3>
        <Grid mdGridCols={2} lgGridCols={2}>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.choiceMakhetha.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Dr. Choice Makhetha</h3>
            <LeadText css={tw`mb-0`}>
              Senior Director: Student Affairs, Stellenbosch University
            </LeadText>
            <p>
              Dr. Choice Makhetha is the Senior Director: Student Affairs at
              Stellenbosch University as from 1 August 2019. She is deeply
              involved in institutional initiatives focusing on Gender-Based
              Violence and Transformation, among others. She also serves as a
              member of the Institutional Audit Committee (IAC) of the Higher
              Education Quality Committee (HEQC), reporting to the Council on
              Higher Education (CHE) since February 2020 - February 2023. She
              obtained her PhD in Political Science in 2003, at the University
              of Free State, in the field of Elections and Electoral Systems -
              South Africa's perspective, funded by the Mellon Foundation. Dr
              Makhetha was on a 19-month contract serving as the Transformation
              Advisor: Office of the Vice Chancellor, at the Central University
              of Technology (CUT), in the Free State Province (January 2018-July
              2019). Before then, she was the Vice Rector Student Affairs and
              External Relations at the University of the Free State (March 2012
              - May 2016) and was acting in the same post for a year (2011)
              before full appointment.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.dereshRamjugernath.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Deresh Ramjugernath</h3>
            <LeadText css={tw`mb-0`}>
              Deputy Vice-Chancellor: Learning and Teaching, Stellenbosch
              University
            </LeadText>
            <p>
              Professor Deresh Ramjugernath has a PhD in Engineering (Chemical)
              from the University of Natal. He is currently the Deputy
              Vice-Chancellor: Learning and Teaching at Stellenbosch University.
              Prior to his appoint at Stellenbosch University he was the Deputy
              Vice-Chancellor: Research and Pro Vice-Chancellor: Innovation,
              Commercialization and Entrepreneurship at the University of
              KwaZulu-Natal (UKZN). He was the holder of the DST/NRF South
              Africa Research Chair (SARChI): Fluorine Process Engineering and
              Separation Technology at UKZN and was appointed full professor of
              chemical engineering, at the age of 31, in January 2004 at UKZN.
              He has been the recipient of numerous awards and recognition,
              including the NRF President‚ Äôs Award in 2005; NSTF Awards in
              2006 and 2010; UKZN Vice-Chancellor‚ Äôs Research Awardee in 2010;
              honorary professorship from Durban University of Technology in
              2012; inducted as a member of the Academy of Science of South
              Africa in 2012; inducted as a fellow of the South African Academy
              of Engineers in 2013; South African Institute of Chemical
              Engineers Gold Medal in 2013; South African Institute of Chemical
              Engineers Innovation Award in 2013 (member of the team); listed by
              Thomson Reuters in 2013 as one of the most highly cited
              researchers; Fellow of the African Academy of Science in 2016;
              Fellow of the University of KwaZulu-Natal in 2017; and most
              recently the Universities South Africa Entrepreneurial Development
              in Higher Education Deputy Vice-Chancellor Award in 2020.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.haroonBhorat.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Haroon Bhorat</h3>
            <LeadText css={tw`mb-0`}>
              Director: Development Policy Research Unit, University of Cape
              Town
            </LeadText>
            <p>
              Haroon Bhorat is Professor of Economics and Director of the
              Development Policy Research Unit at the University of Cape Town.
              His research interests cover labour economics, poverty and income
              distribution. Haroon sits on the Presidential Economic Advisory
              Council (PEAC) and holds the highly prestigious National Research
              Chair under the theme of Economic Growth, Poverty and Inequality:
              Exploring the Interactions for South Africa. He is a Non-resident
              Senior Fellow at the Brookings Institution; a Fellow of the UCT
              College of Fellows; a Research Fellow at IZA, the Institute for
              the Study of Labour in Bonn; and an Honorary Research Fellow at
              the Human Sciences Council (HSRC). He sits on the editorial
              advisory board of the World Bank Economic Review, and is a Board
              Member of the National Research Foundation (NRF) and UNU World
              Institute for Development Economics Research (UNU-WIDER). Haroon
              consults with international organizations such as the ILO, the
              UNDP, the World Bank, Ratings Agencies and emerging market fund
              managers, and has undertaken extensive work for several South
              African government departments‚ and most notably the South African
              Department of Labour, the Presidency and the National Treasury. He
              served as an economic advisor to two past Ministers of Finance and
              previous Presidents Thabo Mbeki and Kgalema Motlanthe, formally
              serving on the Presidential Economic Advisory Panel.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.sharleneSwartz.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Sharlene Swartz</h3>
            <LeadText css={tw`mb-0`}>
              Division Executive: Inclusive Economic Development research
              programme, Human Sciences Research Council
            </LeadText>
            <p>
              Professor Sharlene Swartz is Division Executive of the Inclusive
              Economic Development research programme at the Human Sciences
              Research Council, an Adjunct Professor of Philosophy at the
              University of Fort Hare, and a former Associate Professor of
              Sociology at the University of Cape Town. She holds undergraduate
              degrees from the University of the Witwatersrand and the
              University of Zululand; a Master's degree from Harvard University
              and a PhD from the University of Cambridge. Her expertise and
              current research centres on the just inclusion of youth in a
              transforming society. She is currently completing a book on
              decolonising methodologies in educational research; is working on
              the concept of ‘navigational capacities’ for youth in adverse
              contexts; and is formulating the notion of ‘refracted economies’
              as a tool for thinking about livelihoods and the future of work in
              a digital age. She has over 80 academic publications. Her books
              include The Oxford Handbook of Global South Youth Studies (2021);
              Society, Research and Power (2021); Another Country: Everyday
              Social Restitution (2016); Ikasi: the moral ecology of South
              Africa’s township youth (2009); Teenage Tata: Voices of Young
              Fathers in South Africa (2009); Youth citizenship and the politics
              of belonging (2013); Moral eyes: Youth and justice in Cameroon,
              Nigeria, Sierra Leone and South Africa (2018) and Studying while
              black: Race, education and emancipation in South African
              universities (2018).
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.tshepoMadlingozi.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Tshepo Madlingozi</h3>
            <LeadText css={tw`mb-0`}>
              Director: Centre for Applied Legal Studies, Wits University
            </LeadText>
            <p>
              Tshepo Madlingozi is an Associate Professor and Director of the
              Centre for Applied Legal Studies at Wits University. He has
              master’s degrees in both Law and Sociology, and he received his
              PhD degree from Birkbeck, University of London. He is a Research
              Associate at the Chair for Critical Studies in Higher Education at
              Nelson Mandela University. He is the co-editor of South African
              Journal of Human Rights and part of the management team of
              Pretoria University Law Press. He is a co-editor of Symbol or
              Substance: Socio-economic Rights in South Africa (Cambridge UP)
              and a co-editor of Introduction to Law and Legal Skills in South
              Africa, 2nd Edition (Oxford UP South Africa). He is an advisory
              board member of the Journal of Human Rights Practice, and he sits
              on the boards of the following civil society organizations:
              Amandla.mobi; Centre for Human Rights, University of Free State;
              the Council for the Advancement of the South African Constitution;
              the Rural Democracy Trust; and the Mining-Affected Communities
              United in Action/Women-Affected by Mining Action. He is also a
              member of the Steering Committee of the African Coalition for
              Corporate Accountability (ACCA), and a member of the advisory
              board of Health Justice Initiative. For thirteen years (2015-2018)
              he worked with and for Khulumani Support Group, a 120 000-strong
              social movement of victims and survivors of Apartheid as National
              Advocacy Coordinator and later the Chairperson.
            </p>
          </div>
        </Grid>
        <h3>Social Justice Summit</h3>
        <Grid mdGridCols={2} lgGridCols={2}>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.aurelienLechevallier.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Aurélien Lechavellier</h3>
            <LeadText css={tw`mb-0`}>
              French Ambassador to South Africa
            </LeadText>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.edwinCameron.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Justice Edwin Cameron</h3>
            <LeadText css={tw`mb-0`}>
              Former Judge: Constitutional Court of South Africa
            </LeadText>
            <p>
              Justice Edwin Cameron is a former judge of South Africa’s highest
              court, the Constitutional Court. During apartheid, he was a human
              rights lawyer. In 2000 he was awarded the Nelson Mandela Award for
              Health and Human Rights. Cameron has fought for LGBTI equality and
              was a fierce critic of former South African president Thabo
              Mbeki’s AIDS denialist policies. After retiring from the bench in
              2019, he was elected Chancellor of Stellenbosch University and
              appointed judicial inspector of correctional services.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.lorenzoFioramonti.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Lorenzo Fioramonti</h3>
            <LeadText css={tw`mb-0`}>
              Former Minister of Education, University and Research: Italy
            </LeadText>
            <p>
              Lorenzo Fioramonti is the former Minister of Education, University
              and Research and a member of the Chamber of Deputies in Italy. He
              is Full Professor of Political Economy at the University of
              Pretoria, where he founded the Centre for the Study of Governance
              Innovation and was Deputy Project Leader of the Future Africa
              initiative. Co-author of the Wellbeing Economy theory, he is also
              Extraordinary Professor at the School of Public Leadership of the
              University of Stellenbosch, Senior Fellow at the Centre for Social
              Investment of the University of Heidelberg and at the Hertie
              School of Governance (Germany) and Associate Fellow at the UN
              University.{' '}
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.nicolaSmit.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Nicola Smit</h3>
            <LeadText css={tw`mb-0`}>
              Dean and Professor of Law: Faculty of Law, Stellenbosch University
            </LeadText>
            <p>
              Nicola Smit started her academic career as a junior lecturer at
              UNISA. She joined the University of Johannesburg (then RAU) in
              1996 as a lecturer. She eventually progressed to full professor
              and also served as Vice Dean of the UJ Faculty of Law. In 2013 she
              joined the NWU Faculty of Law as Executive Dean in Potchefstroom.
              Prof Smit has been Dean and Professor of Law of the Faculty of Law
              at Stellenbosch since August 2017. Her areas of specialisation
              include labour law (both collective and individual) and social
              security law. Prof Smit is a NRF-rated researcher and she
              publishes widely and delivers keynotes and presentations at
              national and international conferences. Although Prof Smit does
              not currently teach at undergraduate level, she continues to
              supervise postgraduate students in the fields of labour and social
              security law. For a number of years, she has also taught on the
              Global Law Programme of the KU Leuven. Prof Smit is a member of
              the International Advisory Board of The International Journal of
              Labour Law and Industrial Relations, member of the editorial board
              of International Labor Rights Case Law Journal, and co-editor and
              co-author of various books, including Law@work, Principles and
              Practice of Labour Law, Social Security: A Legal Analysis,
              Introduction to Social Security, Age Discrimination and Labour Law
              and The Law of Commerce.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.nthabisengMoleko.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Dr. Nthabiseng Moleko</h3>
            <LeadText css={tw`mb-0`}>Development Economist</LeadText>
            <p>
              Dr. Nthabiseng Moleko is a Development Economist who is core
              faculty member at the University of Stellenbosch Business School
              (USB) where she teaches Economics and Statistics as a Senior
              Lecturer since 2017. She also serves as a Commissioner for the
              Commission for Gender Equality appointed by the President in 2017
              and is currently the Deputy Chairperson of the Commission. She
              completed her PhD in Development Finance at USB on Pension Funds
              and national development and is the first South African woman to
              be conferred a doctorate in this discipline. Dr. Moleko regularly
              appears across various network's programming on economic and
              business coverage as a thought leader.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.thembiSiweya.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Thembi Siweya</h3>
            <LeadText css={tw`mb-0`}>
              Deputy Minister in The Presidency, South Africa
            </LeadText>
            <p>
              Ms Thembi Rhulani Siweya is a youth activist and a philanthropist
              who is very passionate about promoting a culture of reading and
              writing. She is a graduate from Medunsa and is currently enrolled
              for an MBA Degree. She Matriculated in Saint Morgens English
              Medium School in Mokopane Limpopo province. She was introduced to
              politics at an early age through student activism. She served as
              the Chairperson of SRC Science Faculty in Medunsa (Medical
              University of South Africa) and also Treasurer General (TG) For
              the South African Student Congress (SASCO). In the year 2013, she
              served in the ANCYL Provincial Task Team (PTT) Sub-Committee on
              Communications. She has been a member of the National Executive
              Committee (NEC) of African National Congress Youth League (ANCYL)
              Since 2015 to date. A communicator in her own right, Ms Siweya has
              vast experience of working in the communications environment both
              as a civil servant and as a politician. She worked as the
              Spokesperson for the Youth Commission in Limpopo Province between
              2007 and 2010. She was a Communications Manager in the Office of
              Limpopo Premier between 2010 and 2016. Ms Siweya is an avid reader
              and a writer who has written numerous published opinion pieces on
              a variety of topics specifically about Africa and the world. She
              counts the likes of Chinua Achebe, Bessie Head and Mandla Langa,
              Among her favourite Authors. In 2014, she started African
              Unmasked, a cognitive movement established to deliberate on
              current affairs, especially issues relating to gender, health,
              education, governance, economics and Identity. African Unmasked
              encourages dialogue among Africans with the aim to facilitate
              interdependence amongst African states. Through African Unmasked,
              Ms Siweya has donated learning materials such as books, school
              uniform and shoes to learners in various schools in all the nine
              provinces of South Africa. She has also been able to donate relief
              parcels to the victims of cyclone Idai in Zimbabwe and Mozambique.
              Between April and May 2019. Ms Siweya is the Deputy Minister in
              The Presidency of the Republic of South Africa, the position she
              was appointed to on 30 May 2019. The Deputy Minister is also a
              Member of Parliament for the ANC.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.ustaKaitesi.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Dr. Usta Kaitesi</h3>
            <LeadText css={tw`mb-0`}>CEO: Rwanda Governance Board</LeadText>
            <p>
              Dr. Usta Kaitesi is the Chief Executive Officer of the Rwanda
              Governance Board and a commissioner to the Rwanda Law Reform
              Commission. She previously served as Deputy Chief Executive
              Officer of the Rwanda Governance Board, the principal of the
              University of Rwanda College of Arts and Social Sciences, the Vice
              Chair of the Technical Commission to Support the Parliament in the
              revision of the Constitution of the Republic of Rwanda, vice-dean
              for postgraduate, and head of the department of Public Law as well
              as a lecturer at the former National University of Rwanda. Usta
              Kaitesi was also an advocate with the Rwanda Bar Association and a
              member of the East African Law Society. Dr. Usta Kaitesi sat on
              various Board of directors, National Commission and Advisory
              boards at the International and Nationally levels including;
              member of the International Advisory Board of the Transitional
              Justice Program of the Minerva Centre for Human Rights of the
              Hebrew University of Jerusalem, Vice Chair of the Board of
              Directors of the Rwanda Biomedical Centre, Board Member of the
              Rwanda Governance Board, Vice Chair of the Executive Council of
              the Inter-University Council for East Africa as well as member of
              the advisor.
            </p>
          </div>
        </Grid>
      </Section>

      {/* <Section id="registration" isShaded>
        <h2>Registration</h2>
        <LeadText>
          The 3rd Annual Social Justice Summit and International Conference is a
          fully virtual experience. Online registration is now open!
        </LeadText>
        <p>
          Registration is free of charge, is valid for both events, and
          includes:
        </p>
        <ul>
          <li>Virtual attendance to all sessions</li>
          <li>Access to on-demand sessions for one month after the event</li>
          <li>Presentation slide downloads (where applicable)</li>
        </ul>
        <Button
          to="https://evolve.eventoptions.co.za/register/socialjustice2021"
          size="md"
        >
          Register now
        </Button>
              </Section> */}

      <Section id="watch" isShaded>
        <h2>Watch now</h2>
        <LeadText>
          Experience the summit by watching the playlist below. You can{' '}
          <SafeLink
            to="https://www.youtube.com/playlist?list=PLwTL48ihN7WY4cLXPzqOqTSqO6KfZ9t5o"
            css={tw`border-b-2 border-maroon hover:text-maroon`}
          >
            browse all sessions
          </SafeLink>{' '}
          here.
        </LeadText>
        <h3>Full Summit</h3>
        <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/BbEQUcEVhjI?rel=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            css={tw`absolute top-0 left-0 w-full h-full border-0`}
          />
        </div>
        <h3>Full Conference</h3>
        <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/LZiu_XXnCuQ?rel=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            css={tw`absolute top-0 left-0 w-full h-full border-0`}
          />
        </div>
        <h3>Clips</h3>
        <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/videoseries?list=PLwTL48ihN7WY4cLXPzqOqTSqO6KfZ9t5o"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            css={tw`absolute top-0 left-0 w-full h-full border-0`}
          />
        </div>
      </Section>
      {/* <Section id="press" isShaded>
        <h2>Press coverage</h2>
        <LeadText>
          The 2020 Summit has generated significant coverage in the South
          African press. Here is a selection of the latest articles:
        </LeadText>
        <ol>
          <li>
            <SafeLink
              to="https://www.dailymaverick.co.za/article/2020-11-05-thuli-madonselas-m-plan-could-it-be-south-africas-answer-to-eradicating-poverty-and-inequality/"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Thuli Madonsela’s ‘M Plan’ – could it be South Africa’s answer to
              eradicating poverty and inequality?
            </SafeLink>
            <br />
            <em>The Daily Maverick</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="https://www.news24.com/news24/southafrica/news/we-need-to-speak-honestly-about-incompetent-state-officials-prof-adam-habib-20201105"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              'We need to speak honestly about incompetent state officials' -
              Prof Adam Habib
            </SafeLink>
            <br />
            <em>News24</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="https://www.timeslive.co.za/news/south-africa/2020-11-05-the-poor-dont-need-handouts-they-need-empowerment-madonsela/"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              The poor don't need handouts, they need empowerment: Madonsela
            </SafeLink>
            <br />
            <em>TimesLive</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="https://www.polity.org.za/article/law-trust-social-justice-launches-plan-to-eradicate-poverty-2020-11-04"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Law Trust Chair in Social Justice launches plan to eradicate
              poverty
            </SafeLink>
            <br />
            <em>Polity</em>, 04/11/2020
          </li>
          <li>
            <SafeLink
              to="/downloads/posts/summit-2020-press-argus.pdf"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Faith and Social Justice
            </SafeLink>
            <br />
            <em>Cape Argus</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="/downloads/posts/summit-2020-press-sowetan.pdf"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Madonsela sets her M-Plan in motion
            </SafeLink>
            <br />
            <em>Sowetan</em>, 05/11/2020
          </li>
        </ol>
          </Section> */}
    </Layout>
  );
};

export default Summit2021;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "home-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    summit2021Banner: file(relativePath: { eq: "summit-2021-banner.jpg" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aurelienLechevallier: file(
      relativePath: { eq: "2021-10-aurelien-lechevallier.jpg" }
    ) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    choiceMakhetha: file(relativePath: { eq: "2021-10-choice-makhetha.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    dereshRamjugernath: file(
      relativePath: { eq: "2021-10-deresh-ramjugernath.jpg" }
    ) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    edwinCameron: file(relativePath: { eq: "2021-10-edwin-cameron.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    haroonBhorat: file(relativePath: { eq: "2021-10-haroon-bhorat.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    lorenzoFioramonti: file(
      relativePath: { eq: "2021-10-lorenzo-fioramonti.jpg" }
    ) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    nicolaSmit: file(relativePath: { eq: "2021-10-nicola-smit.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    nthabisengMoleko: file(
      relativePath: { eq: "2021-10-nthabiseng-moleko.jpg" }
    ) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sharleneSwartz: file(relativePath: { eq: "2021-10-sharlene-swartz.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    thembiSiweya: file(relativePath: { eq: "2021-10-thembi-siweya.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    tshepoMadlingozi: file(
      relativePath: { eq: "2021-10-tshepo-madlingozi.jpg" }
    ) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    ustaKaitesi: file(relativePath: { eq: "2021-10-usta-kaitesi.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
